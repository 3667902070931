import EmailsVerificationService from '@/services/emails-verification.service.js'
import DateRange from '@/components/DateRange/DateRange.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import EventBus from '@/util/EventBus'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import getEnv from '@/util/env'
import EmailMigrationConfirmationDialog from '../EmailMigrationConfirmationDialog/EmailMigrationConfirmationDialog.vue'
import EmailMigrationSummaryDialog from '../EmailMigrationSummaryDialog/EmailMigrationSummaryDialog.vue'
import { AwsEmailDnsProvider } from '../EmailsVerificationAssistant/AwsEmailDnsProvider'

export default {
  name: 'EmailsVerificationTable',
  components: {
    DateRange,
    HeaderTopDashboard,
    EmailMigrationConfirmationDialog,
    EmailMigrationSummaryDialog,
  },
  data: function () {
    return {
      show: false,
      loading: true,
      options: {},
      content: [],
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalRows: 0,
      replyEmail: null,
      answerEmailDialog: false,
      retryFromEmailDialog: false,
      retryReplyEmailDialog: false,
      deleteEmailFromDialog: false,
      deleteEmailReplyDialog: false,
      emailReValidationDialog: false,
      showMigrationConfirmationDialog: false,
      showMigrationSummaryDialog: false,
      emailItem: {
        email: null,
      },
      searchTerm: '',
      globalControl: new GlobalControl(),
      debouncedInput: undefined,
      timeout: undefined,
      domainData: {},
      awsStatuses: [],
      showTXTHelp: false,
      showMXHelp: false,
      showDKIMHelp: false,
      currentItem: {},
      migratedDomain: {},
    }
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getEmailsData()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getEmailsData()
      },
      deep: true,
    },
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Id').toString(), value: 'id' },
        { text: 'Email', value: 'email' },
        { text: this.$t('Tipo').toString(), value: 'type' },
        { text: this.$t('Fecha Verificación').toString(), value: 'created_at' },
        { text: this.$t('Última Modificación').toString(), value: 'updated_at' },
        { text: this.$t('Estado').toString(), value: 'status' },
        { text: this.$t('Acciones').toString(), value: 'actions' },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    canEmailV2 () {
      return this.$store.getters['auth/getUser'].enable?.emailV2
    },
  },

  mounted () {
    this.getEmailsData()
    this.getAwsStatuses()
  },
  methods: {
    isAmazonSes (item) {
      return item.dns_provider_id === AwsEmailDnsProvider.SES_ID
    },
    isAmazonRoute53 (item) {
      return item.dns_provider_id === AwsEmailDnsProvider.ROUTE53_ID
    },
    isVerifiedFrom (item) {
      return item.status.name === 'Success' && item.whitelisted !== null
    },
    getEmailsData () {
      EventBus.$emit('showLoading', true)
      this.loading = true
      const data = {
        searchTerm: this.searchTerm,
      }
      EmailsVerificationService.getEmailsData(data)
      .then((emails) => {
        this.content = emails
        this.totalRows = this.content.length
        this.loading = false
      })
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    getAwsStatuses () {
      EmailsVerificationService.getAwsStatuses()
      .then((response) => {
        this.awsStatuses = response.awsStatuses
      })
    },
    canRetry (item) {
      const fromCanRetry = item.type === 'from' && [3, 4].includes(item.status?.id)
      const replyCanRetry = item.type === 'reply' && item.active === 0
      return fromCanRetry || replyCanRetry
    },
    isVerified (item) {
      const fromCanRetry = item.type === 'from' && item.status?.id === 2 && item.domain
      return fromCanRetry
    },
    sendVerifyReplyEmail () {
      if (!this.replyEmail.trim()) {
        return
      }
      const params = {
        email: this.replyEmail,
      }
      EmailsVerificationService.verifyReplyEmailAddress(params)
      .then(
        (response) => {
          if (!response.error) {
            this.getEmailsData()
            EventBus.$emit('showAlert', 'success', response.message)
          } else {
            EventBus.$emit('showAlert', 'danger', response.message)
          }
          this.replyEmail = null
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error.message)
        },
      )

      this.answerEmailDialog = false
    },
    retryVerification (item) {
      this.emailItem = item
      if (item.type === 'from') {
        this.retryFromEmailDialog = true
      } else {
        this.retryReplyEmailDialog = true
      }
    },
    revalidateDomain (item) {
      this.emailItem = item
      EmailsVerificationService.getDns(item.id)
      .then(
        (response) => {
          this.domainData = response
        },
      )
      .finally(() => {
        this.emailReValidationDialog = true
      })
    },
    resendFullDomainVerification () {
      EmailsVerificationService.verifyDomain({
        domain_id: this.emailItem.domain.id,
      })
      .then(
        (response) => {
          console.log(response)
          this.domainData = response
        },
        (err) => {
          console.log(err)
        },
      )
    },
    resendDomainTxtVerification () {
      EmailsVerificationService.verifyDomainTxt({
        domain_id: this.emailItem.domain.id,
      })
      .then(
        (response) => {
          console.log(response)
          this.domainData.dns.txt = response.txt
        },
        (err) => {
          console.log(err)
        },
      )
    },
    resendDomainMxVerification () {
      EmailsVerificationService.verifyDomainMx({
        domain_id: this.emailItem.domain.id,
      })
      .then(
        (response) => {
          console.log(response)
          this.domainData.dns.mx = response.mx
        },
        (err) => {
          console.log(err)
        },
      )
    },
    resendDomainDkimVerification () {
      EmailsVerificationService.verifyDomainDkim({
        domain_id: this.emailItem.domain.id,
      })
      .then(
        (response) => {
          console.log(response)
          this.domainData.dns.dkim = response.dkim
        },
        (err) => {
          console.log(err)
        },
      )
    },
    deleteEmail (item) {
      this.emailItem = item
      if (item.type === 'from') {
        this.deleteEmailFromDialog = true
      } else {
        this.deleteEmailReplyDialog = true
      }
    },
    sendRetryVerifyFromEmail () {
      const params = {
        email: this.emailItem.email,
      }
      EmailsVerificationService.verifyEmailAddress(params)
      .then(
        (response) => {
          if (!response.error) {
            EventBus.$emit('showAlert', 'success', response.message)
          } else {
            EventBus.$emit('showAlert', 'danger', response.message)
          }
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error.message)
        },
      )

      this.retryFromEmailDialog = false
    },
    sendRetryVerifyReplyEmail () {
      const params = {
        email: this.emailItem.email,
      }
      EmailsVerificationService.verifyReplyEmailAddress(params)
      .then(
        (response) => {
          if (!response.error) {
            EventBus.$emit('showAlert', 'success', response.message)
          } else {
            EventBus.$emit('showAlert', 'danger', response.message)
          }
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error.message)
        },
      )

      this.retryReplyEmailDialog = false
    },
    deleteEmailFromVerification () {
      EmailsVerificationService.deleteEmailAddress(this.emailItem.id)
      .then(
        (response) => {
          if (!response.error) {
            const idx = this.content.findIndex(e => e.id === this.emailItem.id)
            this.content.splice(idx, 1)
            EventBus.$emit('showAlert', 'success', response.message)
          } else {
            EventBus.$emit('showAlert', 'danger', response.message)
          }
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error.message)
        },
      )

      this.deleteEmailFromDialog = false
    },
    deleteEmailReplyVerification () {
      EmailsVerificationService.deleteReplyEmailAddress(this.emailItem.id)
      .then(
        (response) => {
          if (!response.error) {
            const idx = this.content.findIndex(e => e.id === this.emailItem.id)
            this.content.splice(idx, 1)
            EventBus.$emit('showAlert', 'success', response.message)
          } else {
            EventBus.$emit('showAlert', 'danger', response.message)
          }
        },
        (error) => {
          EventBus.$emit('showAlert', 'danger', error.message)
        },
      )

      this.deleteEmailReplyDialog = false
    },
    stripName (txt) {
      if (txt === this.domainData.domain.domain) {
        return '@'
      } else {
        return txt.replace(new RegExp('.' + this.domainData.domain.domain + '$'), '')
      }
    },
    exportPdf (item) {
      const domain = item.email.split('@')[1]
      const dnsProvider = item.dns_provider_id
      EmailsVerificationService.getDomain({ domain, dnsProvider })
      .then(
        (response) => {
          const domainId = response.domain.id
          const user = JSON.parse(localStorage.getItem('user'))
          window.open(getEnv('VUE_APP_API_URL') + 'api/account/emails/dns-pdf/' + dnsProvider + '/' + domainId + '?token=' + user.token, '_blank')
        },
        (error) => {
          EventBus.$emit('showAlert', 'warning', error.response.data.message)
        },
      )
    },
    showMigrateConfirmationDialog (item) {
      console.log(item)
      this.currentItem = item
      this.showMigrationConfirmationDialog = true
    },
    migrateToV2 () {
      this.showMigrationConfirmationDialog = false
      const that = this
      console.log(this.currentItem)
      const params = {
        domainId: this.currentItem.domain.id,
      }

      EventBus.$emit('showLoading', true)

      EmailsVerificationService.migrateDomainToV2(params)
      .then(response => {
        console.log(response)
        if (response.error !== 0) {
          that.showMigrationDomainErrors(response.error, that)
          return
        }

        this.migratedDomain = response
        this.migratedDomain.awsStatuses = this.awsStatuses
        console.log(this.migratedDomain)

        this.getEmailsData()
        EventBus.$emit('showAlert', 'success', this.$t('Dominio migrado correctamente'))
      })
      .finally(() => {
        this.currentItem = {}
        this.showMigrationSummaryDialog = true
        EventBus.$emit('showLoading', false)
      })
    },

    showMigrationDomainErrors (error, ctx) {
      let message = ''
      switch (error) {
        case 1:
          message = ctx
            .$t('El dominio indicado no existe')
            .toString()
          break
        case 2:
          message = ctx
            .$t('El dominio indicado no es tuyo')
            .toString()
          break
        case 5:
          message = ctx
            .$t('Ha habido un error al migrar el dominio indicado')
            .toString()
          break
      }
      EventBus.$emit('showAlert', 'danger', message)
    },

    closeMigrationSummaryDialog () {
      this.migratedDomain = null
      this.showMigrationSummaryDialog = false
    },

    notVerifiedStatus (item) {
      let status = ''
      if (item.dns_provider_id === AwsEmailDnsProvider.SES_ID) {
        status += item.verifiedTxt ? 'TXT, ' : ''
        status += item.verifiedMx ? 'MX, ' : ''
        status += item.verifiedDkim ? 'DKIM, ' : ''
      } else {
        status += 'NS, '
      }
      return status.slice(0, -2)
    },
  },
}
